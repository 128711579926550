<template>
<div class="box-css">
  <div  :key="item.id" v-for="(item) in list">
    <span  class="title-css">  {{item.title}}</span>

    <span class="context-css" style="margin-top: 0.3rem;display:inline-block;" v-html="item.context"></span>

  </div>
</div>
</template>

<script>
export default {
  name: 'ContentHelp',
  data() {
    return {
      //参数
      listId: Number(this.$route.query.listId),
      dark: this.$route.query.dark,
      helpList: {
        common: [
          // 常见问题
          {id: 101, title: '1.性别填错了怎么办？', context: '答：为了保障平台的用户真实性，用户注册账号后性别是无法更改的。<br>如若填错，请重新注册新账号。'},
          {
            id: 102, title: '2.金币宝石是什么？怎么获取？', context: '答：1.金币是什么，怎么获取？<br>' +
                '金币是平台奖励用户的虚拟货币单位，（金币超过1000将不再增加）男性用户可用于聊天消耗，女性用户暂未开发金币功能。' +
                '<br><br>' +
                '获取途径：每日签到可获得，进入【我的】打开【我的金币】点击签到获取。\n' +
                '<br><br>' +
                '2.宝石是什么，怎么获取？<br>' +
                '宝石是平台消费的虚拟货币单位，宝石可用于用户聊天消耗、购买聊天礼物和兑换提现。' +
                '<br><br>' +
                '获取途径：用户充值获取，打开路径—【我的】—【我的钱包】—完成充值获取。'
          },
          {
            id: 103, title: '3.宝石兑换规则？', context:
                '答：兑换规则如下<br>' +
                '兑换在 2300>兑换宝石>220 区间时,比例为220:1<br>' +
                '兑换在 5000>兑换宝石>2300 区间时,比例为200:1<br>' +
                '兑换在10000>兑换宝石>5000 区间时,比例为190:1<br>' +
                '兑换在20000>兑换宝石>10000 区间时,比例为180:1<br>' +
                '兑换宝石>20000时,比例为170:1<br>' +
                '兑换到帐时间为1个工作日内',
          },
        ],
        appPlay: [
          {
            id: 201, title: '1.关于我们？', context: '在陌趣，你可以通过文字互动、多种快速匹配、每天会为你个性化精准推荐多位真实用户等多种渠道，帮你快速找到你心仪的另一半，让你从此不再孤单！',
          },
          {
            id: 202, title: '2.怎么才能快速认识？', context: '答：相识要从多方面了解，你可以上传照片、写下文字、多发动态，让对方看到你的生活状态和兴趣爱好。\n' +
                '对此我们也总结了受欢迎的用户的共同特征：<br>' +
                '1.本人真实的照片。（有的话会显得很真诚）\n' +
                '<br>' +
                '2.写下自己个性的标签文字，会让你看起来真实有趣！\n' +
                '<br>' +
                '相对完善的资料不光会增加对方对你的好感度，还能让聊天更有话题哦！',
          },
        ],
        match: [
          {
            id: 301,
            title: '1.漂流瓶怎么玩？”',
            context: '答：你好，您可通过文字进行互动，寻找您感兴趣的内容进行互动聊天，也可以留下您的蜜语，帮您快速呼唤心仪的另一半，让您从此不在孤单！'
          },
          {
            id: 302,
            title: '2.随机匹配怎么玩？ ',
            context: '答：在首页点击随机匹配按钮，匹配时系统会为您精准推荐优质用户，匹配会有30秒等待时间，在等待期间，需要另一用户也点击立即匹配按钮，才能匹配成功。\n' +
                '匹配成功后可选择进行对话，也可以选择重新匹配。'
          },
        ],
        banned: [
          {
            id: 401, title: '1.账号被封禁，里面的宝石怎么办？', context: '答：你好，账号被封禁期间宝石会被冻结，无法进行提现，封号时间一到将自动解封，正在提现中的宝石退回，可重新进行提现。'
          },
          {
            id: 402,
            title: '2.账号为什么被封？',
            context: '答：你好,根据《社交平台规范》账号如有违规行为，将会按规定进行封号处理，封号时间一到将自动解封，希望能注意自己的言行，共同维护平台和谐，感谢您的支持。 '
          },
          {
            id: 403,
            title: '3.账号多次违规？',
            context: '答：你好,如账号因某项规则多次违规，将进行永久性封禁，希望能珍惜账号，感谢您的理解与支持。 '
          },
        ],
      },
      list: Array
    }
  },
  mounted() {

    let dark = this.dark; // John
    let html = document.documentElement
    if (dark=='true') {
      html.classList.add('dark')
    } else {
      html.classList.remove('dark')
    }
    this.getList()
  },
  methods: {
    getList: function () {
      this.list = []
      if (this.listId ===1) {
        this.list = JSON.parse(JSON.stringify(this.helpList.common))
      } else if (this.listId ===2) {
        this.list = JSON.parse(JSON.stringify(this.helpList.appPlay))
      } else if (this.listId ===3) {
        this.list = JSON.parse(JSON.stringify(this.helpList.match))
      } else if (this.listId ===4) {
        this.list = JSON.parse(JSON.stringify(this.helpList.banned))
      }
    }
  }
}
</script>
