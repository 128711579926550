<template>
  <div class="agreement_css">
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <title>隐私协议</title>
    <header>
    </header>
    <section>
      <section>
        <article>
          <div>
            <p style="font-weight: 600"><span style="color: red;margin-top: 1rem"><br>发布日期：2024年5月15日<br><br>
             生效日期：2024年5月15日</span>
              <br><br>
              欢迎您使用陌趣漂流瓶软件和服务！
              <br>
              连云港万维网络科技有限公司
              （以下简称“我们”）是陌趣漂流瓶软件和服务的运营方，我们深知个人信息对您的重要性，并会尽力保护您的个人信息安全可靠，我们致力于维持您对我们的信任，恪守以下原则，保护您的个人信息：权责一致原则，目的明确原则，选择同意原则，最少够用原则，确保安全原则，主体参与原则，公开透明原则等等。同时我们承诺，我们将按业界成熟的安全解决方案，采取相应的安全保护措施来保护您的个人信息。鉴此，我们制定本《隐私政策》（下称“本政策
              /本隐私政策”）并提醒您：
              <br><br>本政策适用于“陌趣漂流瓶”软件及相关服务。<br>
              在使用“陌趣漂流瓶”软件及相关服务前，请您务必仔细阅读并理解本政策，在确认充分理解并同意后使用相关产品或服务。一旦您使用“陌趣漂流瓶”软件及相关服务，即表示您已充分理解并同意本政策。如对本政策内容有任何疑问、意见或建议，您可通过“陌趣漂流瓶”提供的各种联系方式与我们联系。
              <br>
              【特别提示】我们不面向不满十八周岁的未成年人提供服务，如果您是未成年人，敬请不要注册成为我们的用户并使用我们的产品及服务（详情请查阅本政策第六部分）。
            </p>
          </div>
        </article>
        <article>
          <h2>本隐私政策部分将帮助您了解以下内容：</h2>
          <p>一 、我们向您收集的个人信息及其用途</p>
          <p>二 、我们可能需要获取的您的设备权限</p>
          <p>三 、我们如何共享、转让、披露您的个人信息</p>
          <p>四 、我们如何保护您的个人信息</p>
          <p>五 、您如何管理您的个人信息</p>
          <p>六 、我们如何处理未成年人的个人信息</p>
          <p>七 、我们存储您的个人信息的位置和方式</p>
          <p>八 、本隐私政策如何更新</p>
          <p>九 、如何联系我们</p>
        </article>
        <article>
          <h2>一、我们向您收集的个人信息及其用途</h2>
          <p>个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息，我们将以下述目的，收集和使用您的个人信息：</p>
          <p>
            1、我们会收集您的手机号码、昵称 、头像，用于为您创建“陌趣漂流瓶”的账号并为您提供相关服务；我们会收集 您在Q Q和微信的注册账号相关信息（头像、昵称及您授权的其他信息）及手机号码
            （用来绑定账号），用于为您创建“陌趣漂流瓶”的账号并为您提供相关服务；
          </p>
          <p>
            2、我们会收集您的设备型号、设备设别码(句括IMF/AndrOIdID /IDFA/IDEV/OPFN UDID/GUID/OAID/SNID/MED 广告ID SIM卡手机号IMSI、ICCID)，软件列表、设备运行状态信息、系统版本、MCC、MNC、Mac地址信息(包括手机Mac地址和蓝牙Mac地址)，设备传感器信息(包括重力感应器、磁场感应器、加速度传感器)、设备所在位置相关信息(IP地址、地理位置信息)。收集这些信息是为了帮助我们进行bug分析、反作、
            反黑产等安全保护工作，保障您正常使用本产品与/或服务、改进和优化我们的产品体验保障您的账号安全。同时，除本政策另有明确约走外，我们不会将您的设备信息提供给任何第三方，
            但我们无法保证其他第三方不会通过其他途径获知您设备信息并将您的设备信息与其他信息相结合识别您个人身份，由此造成您任何损失的，您应向该第三方主张赔偿。上述设备信息是为提供服务所收集的基础信息如您不想将上述设备信息被我们收集，则可能导致我们无法向您提供正常的服务。
          </p>
          <p>
            3、我们会收集您填写的性别、出生日期信息，用于使其他用户更加的了解您以及用于我们为您提供更契合您需求的服务以及为您匹配更适合您的用户。
            在注册与使用服务过程中，如果您提供以下额外信息补全个人资料，将有助于我们给您提供更好的服务和体验：个性标签、常居城市、自我介绍。但如果您不提供这些信息，将不会影响使用“陌趣漂流瓶”产品或服务的基本功能。
          </p>
          <p>
            4、为您推荐个性化的内容或服务 为了向所有用户推荐优质内容，我们可能会收集并使用下列信息：
            （1）关注、点击、搜索、（例如：您感兴趣的内容信息）等您的操作、使用行为信息；<br>
            （2）反馈、发布、点赞、评论等您主动提供的信息；
            我们会将上述信息与来自我们其他服务的信息结合，进行综合统计计算并通过人工在“推荐”板块向所有用户推荐优质内容。也会在首次登陆时请求获取“位置信息”权限，向其它用户展示您登录所在的位置，
            请您了解，我们收集、使用上述信息时进行了去标识化处理，数据分析仅对应特定的、无法直接关联用户身份的编码，
            无法也绝不会与您的真实身份相关联。如果您不希望我们向你进行个性化的推荐，可以前往【设置 - 隐私设置 - 个性化推荐】进行关闭。
          </p>
          <p>
            5、我们会收集您提供的微信支付信息、支付宝支付信息，用于为您提供购买平台虚拟货币--宝石的服务。
          </p>
          <p>
            6、我们会根据您在安装及使用“陌趣漂流瓶”软件及相关服务的过程中授予的具体权限收集您所使用的设备相关信息
            （例如设备型号、操作系统版本、设备配置、设备标识、设备网络信息、设备环境、软件列表等软硬件特征信息），用于向您提供更契合您需求的展示和搜索结果、了解产品适配性、识别账号异常状态。
          </p>
          <p>
            7、我们会收集您使用“陌趣漂流瓶”软件及相关服务的网络日志信息（您使用“陌趣漂流瓶”软件及相关服务的日期、时间等），用于识别账号异常状态及满足相关法律法规的要求。
          </p>
          <p>
            8、我们可能使用或整合您的用户信息、设备信息
            及有关网络日志，为提高您使用我们提供服务的安全性，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或“陌趣漂流瓶”相关协议规则的情况，并用于综合判断您账户风险、进行账户验证、检测及防范安全事件，并依法采取必要的记录、分析、处置措施。
          </p>
          <p>
            9、我们可能会在您与我们联系时保存您的通信/通话记录 和内容或您留下的联系方式等信息，以便与您联系或帮助您解决问题，或记录相关问题的处理方案及结果。
          </p>
          <p>
            10、设备信息
            为了使产品与您的移动设备进行必要的适配，为您提供安全、可信的产品与使用环境，保障产品的安全运行，在您使用陌趣漂流瓶时，根据您在软件安装及使用中授予的具体权限，我们会收集您的设备型号、设备识别码（包括IMEI/Android ID/IDFA/IDFV/OPEN UDID/GUID/OAID/SN ID/MEID、DEVICEID、广告ID、SIM卡手机号、IMSI、ICCID）、设备ID、设备型号、操作系统、系统时区、屏幕分辨率、磁盘使用情况、内存使用情况、运行线程数、CPU信息（频率、型号、架构）、移动设备国家编码（MCC）、移动设备网络编码（MNC）、设备dpi、设备运行状态信息、系统版本、MCC、MNC、Mac地址信息（包括手机Mac地址和蓝牙Mac地址）、设备传感器信息、外接虚拟键盘识别、运行中的进程信息。收集这些信息是为了帮助我们进行bug分析、反作弊、反黑产等安全保护工作，保障您正常使用本产品与/或服务、改进和优化我们的产品体验、保障您的账号安全。上述设备信息是为提供服务所收集的基础信息，如您不想将上述设备信息被我们收集，则可能导致我们无法向您提供正常的服务。

           请您知悉，为了帮助我们进行bug分析、反作弊、反黑产等安全保护工作，保障您正常使用本产品与/或服务、改进和优化我们的产品体验、保障您的账号安全，在您的App切换至后台或处于静默状态下时，我们会收集您的Mac地址信息（包括手机Mac地址和蓝牙Mac地址）以及IMEI。
          </p>
          <p>
            11、征得授权同意的例外
          </p>
          <p>
            根据相关法律法规规定，以下情形中收集您的个人信息无需征得您的授权同意：
          </p>
          <p>
            （1） 与我们履行法律法规规定的义务相关的；
          </p>
          <p>
            （2）与国家安全、国防安全有关的；
          </p>
          <p>
            （3）与公共安全、公共卫生、重大公共利益有关的；
          </p>
          <p>
            （4）与犯罪侦查、起诉、审判和判决执行等有关的；
          </p>
          <p>
            （5）出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；
          </p>
          <p>
            （6）所收集的个人信息是您自行向社会公众公开的；
          </p>
          <p>
            （7）从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；
          </p>
          <p>
            （8）根据您的要求签订合同所必需的；
          </p>
          <p>
            （9）法律法规规定的其他情形。
          </p>
          <h2>
            如我们停止运营“陌趣漂流瓶”软件及相关服务，我们将立即停止收集您个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，并按相关法律法规的规定对所持有的个人信息进行删除或匿名化处理。
          </h2>
        </article>

        <article>
          <h2>二、我们可能需要获取的您的设备权限</h2>
          <p>1、我们需要获取访问您的手机相册的授权，用于为您提供选择您手机相册里的一张照片作为您的账号头像或作为动态内容进行发布，如您不使用我们提供的上述服务，则我们不会访问您的手机相册，
            您也可以随时取消对我们访问您的手机相册的授权。</p>
          <p>
            2、我们需要获取访问您的手机相机的授权，用于为您提供提现时的实名认证服务，如您不使用我们提供的上述服务，则我们不会访问您的手机相机， 您也可以随时取消对我们访问您的手机相机的授权。</p>
          <p>3、我们需要获取访问您的手机麦克风的授权，用于为您提供使用您手机麦克风录制您声音，如您不使用我们提供的上述服务，则我们不会访问您的手机麦克风， 您也可以随时取消对我们访问您的手机麦克风的授权。</p>
          <p>4、我们需要获取访问您的地理位置的授权，用于向其他用户展示您的居住城市及/或为您匹配同城用户及/或为您推荐同城用户的动态，如您不使用我们提供的上述服务，则我们不会访问您的地理位置，
            您也可以随时取消对我们访问您的地理位置的授权。</p>
          <p>5、我们需要获取您的手机通知权限，用于我们向您推送相关服务通知，如您不想收到上述通知， 您可以随时关闭我们使用您手机通知的权限。</p>
          <p>6、为确保本应用处于关闭或后台运行状态下可正常接收到客户端推送的信息，本应用须使用(自启动)能力，将存在一定频率通过系统发送广播唤醒本应用自启动或关联启动行为,是因实现功能及服务所必要的。</p>
          <p>7、当您打开内容类推送消息，在征得您的明确同意后，会跳转打开相关内容。在未征得您同意的情况下，则不会有自启动或关联启动。</p>
          <p>8、我们会在您使用相关服务时弹窗提示您是否要开启相应权限。您可在您的设备设置查看上述权限的状态并可自行决定这些权限随时的开启或关闭。请您注意您开启任一权限即代表您授权我们可以收集和使用相关个人信息来为您提供对应服务，您一旦关闭任一权限即代表您取消了授权，我们将不再基于对应权限继续收集和使用相关个人信息，也无法为您提供该权限所对应的服务。但是，您关闭权限的决定不会影响此前基于您的授权所进行的信息收集及使用。</p>
        </article>
        <article>
          <h2>三、我们如何共享、转让、披露您的个人信息</h2>
          <p>（一）共享<br>我们不会与陌趣漂流瓶服务提供者以外的公司、组织和个人共享您的个人信息，但以下情况除外</p>
          <p>1、在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息。</p>
          <p>2、在法定情形下的共享：我们可能会根据法律法规规定、诉讼争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息。</p>
          <p>3、为了使您能够使用下述服务及功能，我们的应用中会接入第三方SDK：</p>
          <div id ="app" align ="center" style="margin-top: 0.2rem;margin-bottom: 0.2rem; display: block; margin-inline-start: 0px;margin-inline-end: 0px; margin-block-start: 1em; margin-block-end: 1em; margin:0; padding:0; font-size: 14px;font-weight: 330;color: rgba(51, 51, 51, 100); font-family:SourceHanSansSC-Regular;" >
            <table border="1" >
              <tr v-for = "(column ,index) in columns1" :key="index" style="word-wrap:break-word;
  word-break:break-all;" >
                <td>{{column.fw}}</td>
                <td>{{column.md}}</td>
                <td>{{column.xx}}</td>
                <td>{{column.qj}}</td>
                <td>{{column.fs}}</td>
                <td >{{column.lj}}</td>
              </tr>
            </table>
          </div>
          <p>（1） 如您使用第三方账号登录服务， QQ、微信登录SDK会收集您的设备相关信息， 用于登录服务</p>
          <p>（2） 如您使用支付功能，由与我们合作的第三方支付机构向您提供服务，第三方支付SDK需要收集 设备相关信息。</p>
          <p>（3） 如您使用实名 认证服务，由与我们合作的第三方机构向您提供服务，阿里实名认证SDK需要您打开相机权限 收集您的生物识别特征（静态或动态的面部特征）提供相应的服务。</p>
          <p>（4） 如您使用账号登录功能，由极验提供的滑动验证码SDK会收集您的 设备相关信息，用于账号安全识别。</p>
          <p>（5） 如您使用地图服务，高德地图SDK需要您打开设备 位置权限，并提供相应的服务。</p>
          <p>（6） 我们的产品集成友盟+SDK，友盟+SDK需要收集您的设备Mac地址、唯一设备识别码（IMEI/android ID/IDFA/OPENUDID/GUID、SIM 卡 IMSI
            信息）以提供统计分析服务，并通过地理位置校准报表数据准确性，提供基础反作弊能力。</p>
          <h2>
            我们会对授权合作伙伴获取有关信息的应用程序接口（API）、软件工具开发包（SDK）进行严格的安全检测，并与授权合作伙伴约定严格的数据保护措施，令其按照我们的委托目的、服务说明、本隐私权政策以及其他任何相关的保密和安全措施来处理个人信息。</h2>
          <p>（二）转让</p>
          <p>我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</p>
          <p>1、在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息。</p>
          <p>
            2、在涉及合并、收购或破产清算情形，或其他涉及合并、收购或破产清算情形时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本政策的约束，否则我们将要求该公司、组织和个人重新向您征求授权同意。</p>
          <br>
          <p>（三）披露</p>
          <p>我们不会向任何第三方披露您的个人信息，但以下情形除外：</p>
          <p>1、您自主自愿选择披露或另行授权我们披露给第三方时，我们会将您同意或选择披露的个人信息披露给您指定的第三方。</p>
          <p>2、我们依据相关法律法规基于下述情形披露您的个人信息：</p>
          <p>（1） 与我们履行法律法规规定的义务相关的；</p>
          <p>（2） 与国家安全、国防安全有关的；</p>
          <p>（3） 与公共安全、公共卫生、重大公共利益有关的；</p>
          <p>（4） 与犯罪侦查、起诉、审判和判决执行等有关的；</p>
          <p>（5） 出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</p>
          <p>（6） 从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；</p>
          <p>（7） 其他法律法规规定或司法机关要求的情形。</p>
          <h2>根据《中华人民共和国网络安全法》等相关法律法规的规定，共享、转让、披露经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、
            转让及披露行为，对此类数据的保存及处理将不再另行向您通知并征得您的同意。</h2>
        </article>
        <article>
          <h2>四、我们如何保护您的个人信息</h2>
          <p>
            1、我们努力为用户的个人信息提供安全保障，以防止用户个人信息的非法使用、未经授权的访问和披露、泄漏、损坏和丢失。我们将在合理的安全水平内使用各种安全保护措施以保障信息的安全。例如，我们会使用加密技术（例如，SSL）、匿名化处理、加密存储等手段来保护你的个人信息；建立受信赖的保护机制防止个人信息遭到恶意攻击；建立访问控制机制，遵守最小权限原则，确保只有授权人员才可访问个人信息；开展安全和个人信息保护培训课程，加强员工对于保护个人信息保护的认识。</p>
          <p>
            2、我们有行业先进的以数据为核心，围绕数据生命周期进行的数据安全管理体系，从管理机构、组织建设、制度体系、产品技术等方面多维度提升整个系统的安全性。我们建立专门的管理制度、流程和组织以保障个人信息的安全。例如，我们严格限制访问信息的人员范围，要求他们遵守保密义务，并进行审计。若发生个人信息泄露等安全事件，我们会启动应急预案，阻止安全事件扩大，并以推送通知、公告等形式告知您。</p>
          <p>3、我们会采取合理可行的措施，尽力避免收集无关的个人信息。如您发现自己的个人信息尤其是您的账户或密码发生泄露，请您立即联络我们，以便我们根据您的申请采取相应措施。
            请注意，您在使用我们服务时自愿共享甚至公开分享的信息，可能会涉及您或他人的个人信息，请您更加谨慎地考虑，是否在使用我们的服务时共享甚至公开分享相关信息。</p>
          <p>同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。</p>
        </article>
        <article>
          <h2> 五、您如何管理您的个人信息</h2>
          <p>您可以通过以下方式访问及管理您的个人信息：</p>
          <p>（一）访问您的个人信息</p>
          <p>您有权访问您的个人信息，法律法规规定的例外情况除外。您可以通过以下方式自行访问您的个人信息：
            账户信息—如果您希望访问或编辑您的账户中的个人资料信息： 头像、昵称、出生日期、常居城市、自我介绍 ，您可以通过通过点击我的-编辑资料来执行此类操作。</p>
          如果您无法通过上述路径访问该等个人信息，您可以随时通过“意见反馈”与我们取得联系。我们将在15天内回复您的访问请求。
          <p>
            对于您在使用我们的产品或服务过程中产生的其他个人信息，我们将根据本条“（ 六）响应您的上述请求”中的相关安排向您提供。</p>
          <p> （二）更正或补充您的个人信息</p>
          <p> 当您发现我们处理的关于您的个人信息有错误时，您有权要求我们做出更正或补充。您可以通过本条“（一）访问您的个人信息”中列明的方式提出更正或补充申请。</p>
          <p>（三）删除您的个人信息</p>
          <p> 您可以通过本条“（一）访问您的个人信息”中列明的方式删除您的部分个人信息。
            在以下情形中，您可以向我们提出删除个人信息的请求：</p>
          <p> 1、 如果我们处理个人信息的行为违反法律法规；</p>
          <p> 2、 如果我们收集、使用您的个人信息，却未征得您的明确同意；</p>
          <p> 3、 如果我们处理个人信息的行为严重违反了与您的约定；</p>
          <p> 4、 如果您不再使用我们的产品或服务，或您主动注销了账号；</p>
          <p> 5、 如果我们永久不再为您提供产品或服务。</p>
          <p> 若我们决定响应您的删除请求，我们会及时删除。</p>
          <p> 当您从我们的服务中删除信息后，我们可能不会立即从备份系统中删除相应的信息，但会在备份更新时删除这些信息。</p>
          <p>（四）改变您授权同意的范围</p>
          <p>每个业务功能需要一些基本的个人信息才能得以完成（见本隐私政策“第一条”）。除此之外，对于额外个人信息的收集和使用，您可以在设置或与“陌趣漂流瓶”客服联系给予或收回您的授权同意。</p>
          <p>
            当您收回同意后，我们将不再使用相应的个人信息。但您收回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。</p>
          <p>（五）个人信息主体注销账户</p>
          <p>您可以自行在“设置”-“账户与安全”-“账号注销”页面提交账户注销申请。</p>
          <p>
            我们按本政策收集的您的个人信息，将在您注销后立即删除或按相关法律法规的规定进行匿名化处理，但按相关法律法规或司法机关的要求需要延长保存时间的除外。在延长保存期间内，我们会以加密形式安全地存储您的个人信息并进行隔离，直至按相关法律法规或司法机关的要求可以删除或匿名化处理。
          </p>
          <h2>（六）响应您的上述请求</h2>
          <p> 为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。</p>
          <p> 我们将在15天内做出答复。如您不满意，还可以通过“陌趣漂流瓶”客服发起投诉。</p>
          <p>
            对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际的请求，我们可能会予以拒绝。
          </p>
          <p> 在以下情形中，按照法律法规要求，我们将无法同意您的请求：</p>
          <p> 1、 与国家安全、国防安全有关的；</p>
          <p> 2、 与公共安全、公共卫生、重大公共利益有关的；</p>
          <p> 3、 与犯罪侦查、起诉、审判和执行判决等有关的；</p>
          <p> 4、 有充分证据表明个人信息主体存在主观恶意或滥用权利的；</p>
          <p> 5、 响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；</p>
          <p> 6、 涉及商业秘密的。</p>
        </article>
        <article>
          <h2>六、我们如何处理未成年人的个人信息</h2>
          <p>
          </p>
          <p> 任何18岁以下的未成年人均不得创建自己的账户或使用我们的服务,我们的产品、服务仅面向18周岁以上的成年人，在您使用我们的服务过程中，我们推定您具有相应的民事行为能力。</p>
          <p> 如果我们发现或监测出任何未成年人注册或用我们产品，我们将采取封禁账户、注销帐户、删除信息等措施禁止未成年人的使用。</p>
        </article>
        <article>
          <h2>七、我们存储您的个人信息的位置和方式</h2>
          <p>我们按本政策收集的您的个人信息，存储在中国境内。</p>
          <p> 我们按本政策收集的您的个人信息，以加密的方式存储。</p>
          <p>
            我们按本政策收集的您的个人信息，将在您删除或注销后立即删除或按相关法律法规的规定进行匿名化处理，但按相关法律法规或司法机关的要求需要延长保存时间的除外。在延长保存期间内，我们会以加密形式安全地存储您的个人信息并进行隔离，直至按相关法律法规或司法机关的要求可以删除或匿名化处理。
          </p>
        </article>
        <article>
          <h2>八、系统权限的扩展业务功能</h2>
          <p>为向您提供更便捷、优质、个性化的产品和服务，我们在向您提供的扩展业务功能中可能会通过开启系统权限以收集和使用您的个人信息。您不同意开启相关权限，不会影响您使用平台的基本服务，但您可能无法获得这些扩展业务功能给您带来的用户体验。</p>
          <p> 您开启任一权限即代表您授权我们可以收集和使用相关个人信息来为您提供对应服务，您一旦关闭任一权限即代表您取消了授权，平台将不再基于对应权限继续收集和使用相关个人信息，也无法继续为您提供该权限所对应的服务。您关闭权限的决定不会影响此前基于您的授权所进行的信息收集及使用。</p>
          <p> 扩展业务功能的应用权限申请与使用情况说明下：</p>
          <p>
            读取电话状态权限：当运行陌趣漂流瓶时，我们会向您申请获取止权限，目的是正常识别您的本机识别码，以便完成安全风控、进行统计和服务推送，您拒绝您可选择关闭此权限。
          </p>
          <p>读写外部存储权限：当使用陌趣漂流瓶中的头像设置、发送图片.发送音频时，您可以通过开启存储权限使用或保存图片、音频或文件。若您不开启此权限，您将无法使用与存储卡读取或缓存相关的功能，但不影响您使用其他服务。</p>
          <p>相机权限：当使用陌趣漂流瓶的设置拍摄头像、发送拍摄图片服务时，可以通过开通相机权限，便进行实时拍摄，上传图片或音频进行晒图或分享设置头像。若您不开启此权限，您将无法使用图片或音频拍摄相关的功能，但不影响您使用其他服务。</p>
          <p>位置权限：当使用陌趣漂流瓶的同城服务时，您可以通过开启位置权限，以便查看或获取当前所在区域的服务。若您不开启此权限，您将无法收到基于当前所在位置进行的推荐，但不影响您使用其他服务。</p>
        </article>
        <article>
          <h2> 九、本隐私政策如何更新</h2>
          <p> 我们的隐私政策可能变更。</p>
          <p> 未经您明确同意，我们不会限制您按照本隐私政策所应享有的权利。对于重大变更，我们会提供显著的通知（包括我们会通过“陌趣漂流瓶”公示的方式进行通知甚至向您提供弹窗提示）。</p>
          <p>本政策所指的重大变更包括但不限于：</p>
          <p> 1、 我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；</p>
          <p> 2、 我们在控制权等方面发生重大变化。如并购重组等引起的所有者变更等；</p>
          <p> 3、 个人信息共享、转让或公开披露的主要对象发生变化；</p>
          <p> 4、 您参与个人信息处理方面的权利及其行使方式发生重大变化；</p>
          <p> 5、 我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；</p>
          <p> 6、 个人信息安全影响评估报告表明存在高风险时。</p>
          <p> 我们还会将本隐私权政策的旧版本存档，供您查阅。</p>
        </article>
        <article>
          <h2>十、如何联系我们</h2>
          <p> 1、 您可以通过“陌趣漂流瓶”客服与我们联系，我们将在15天内回复您的请求。</p>
          <p> 2、 如发现个人信息可能被泄露，您可以发邮件至moqunet@163.com投诉举报。</p>
          <p> 3、 注册地址：连云港市海州区巨龙南路59号君宸大厦409室</p>
          <p> 4、 办公地址：连云港市海州区巨龙南路59号君宸大厦409室</p>
          <p> 5、 联系方式：可通过在线客服或者发送邮件至moqunet@163.com</p>
          <p> 如果您对我们的回复不满意，特别是您认为我们的个人信息处理行为损害了您的合法权益， 您还可以通过向本协议签订地有管辖权的法院提起诉讼来寻求解决方案 。</p>
        </article>
      </section>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Privacy',
  data(){
    return {
      columns1:[
        {fw:'SDK名称/类型',md:'使用目的',xx:'获取信息',qj:'使用范围',fs:'接入方式',lj:'第三方个人信息处理规则'},
        {fw:'华为推送',md:'消息推送',xx:'设备信息（IMEI/MAC/Android ID/SIM卡IMSI等）、后台运行时获取设备信息',qj:'华为推送',fs:'嵌入该SDK，SDK将传输个人信息',lj:'https://consumer.huawei.com/cn/privacy/privacy-policy/'},
        {fw:'VIVO推送',md:'消息推送', xx:'设备信息（IMEI/MAC/Android ID/SIM卡IMSI等）、后台运行时获取设备信息',qj:'VIVO推送',fs:'嵌入该SDK，SDK将传输个人信息',lj:'https://www.vivo.com.cn/about-vivo/privacy-policy'},
        {fw:'OPPO推送',md:'消息推送', xx:'设备信息（IMEI/MAC/Android ID/SIM卡IMSI等）、后台运行时获取设备信息',qj:'OPPO推送',fs:'嵌入该SDK，SDK将传输个人信息',lj:'https://open.oppomobile.com/wiki/doc#id=10288'},
        {fw:'小米推送',md:'消息推送', xx:'设备信息（IMEI/MAC/Android ID/SIM卡IMSI等）、后台运行时获取设备信息',qj:'小米推送',fs:'嵌入该SDK，SDK将传输个人信息',lj:'https://dev.mi.com/console/doc/detail?pId=1822'},
        {fw:'魅族推送',md:'消息推送', xx:'设备信息（IMEI/MAC/Android ID/SIM卡IMSI等）、后台运行时获取设备信息',qj:'魅族推送',fs:'嵌入该SDK，SDK将传输个人信息',lj:'http://static.meizu.com/resources/term/privacy8.html'},
        {fw:'移动推送TPNS',md:'消息推送', xx:'设备信息(手机型号，系统类型、系统版本等)用于标签化推送以及识别是否是真机、网络信息(网络类型)支持根据不同网络类型进行不同类型推送、账号绑定信息(根据您所选用的不同推送渠道，QQ号、微信Union ID、 手机号、邮箱等)用于根据账号信息进行推送。',qj:'移动推送',fs:'嵌入该SDK，SDK将传输个人信息',lj:'https://privacy.qq.com/policy/tencent-privacypolicy'},
        {fw:'腾讯开放平台/QQ互联',md:'QQ登录与分享服务', xx:'设备信息（IMEI/MAC/Android ID/SIM卡IMSI等）、读取已安装包名列表',qj:'第三方登录、分享',fs:'嵌入该SDK，SDK将传输个人信息',lj:'https://privacy.qq.com/policy/tencent-privacypolicy'},
        {fw:'微信SDK',md:'第三方登录、支付',xx:'设备信息（IMEI/MAC/Android ID/SIM卡IMSI等）、读取已安装包名列表',qj:'第三方登录、分享、支付',fs:'嵌入该SDK，SDK将传输个人信息',lj:'https://privacy.qq.com/policy/tencent-privacypolicy'},
        {fw:'支付宝SDK',md:'支付宝支付、提现',xx:'设备信息（IMEI/MAC/Android ID/SIM卡IMSI等）',qj:'支付、提现',fs:'嵌入该SDK，SDK将传输个人信息',lj:'https://opendocs.alipay.com/open/54/01g6qm '},
        {fw:'高德地图',md:'定位', xx:'设备信息（包括MAC地址、Android                ID、OAID、IDFA、操作系统版本、设备品牌及型号、设备配置、应用信息、位置信息（可能包括GNSS信息、WIFI地址及信号强度信息、WiFi地址及信号强度信息、SSID、BSSID、基站ID数据信息、运营商信息、传感器信息（光传感器、磁场传感器、重力传感器、压力传感器、方向传感器、旋转矢量传感器、陀螺仪传感器、加速度传感器）））、连接WiFi信息（包括WiFi状态、WiFi参数和WiFi列表）、地理位置信息、后台运行时获取设备信息和地理位置信息',qj:'推荐附近的人、附近动态、聊天时发送位置信息',fs:'嵌入该SDK，SDK将传输个人信息',lj:'https://lbs.amap.com/home/privacy/'},
        {fw:'阿里云认证',md:'一键登录', xx:'设备信息（IMSI、SIM卡信息）',qj:'一键登录',fs:'嵌入该SDK，SDK将传输个人信息',lj:'http://terms.aliyun.com/legal-agreement/terms/suit_bu1_ali_cloud/suit_bu1_ali_cloud201902141711_54837.html'},
        {fw:'腾讯云即时通讯',md:'IM即时通信',xx:'IP地址，MAC地址，设备信息（IMEI/MAC/Android ID/SIM卡IMSI等）、后台运行时获取设备信息',qj:'IM即时通信',fs:'嵌入该SDK，SDK将传输个人信息',lj:'https://privacy.qq.com/policy/tencent-privacypolicy'},
        {fw:'腾讯云对象存储COS ',md:'图片存储', xx:'设备信息（IMEI/MAC/Android ID/SIM卡IMSI等',qj:'图片上传存储',fs:'嵌入该SDK，SDK将传输个人信息',lj:'https://privacy.qq.com/policy/tencent-privacypolicy'},
        {fw:'腾讯Bugly SDK ',md:'统计分析', xx:'设备信息（IMEI/MAC/Android ID/SIM卡IMSI等）、后台运行时获取设备信息、获取运行中进程信息',qj:'统计分析',fs:'嵌入该SDK，SDK将传输个人信息',lj:'https://privacy.qq.com/policy/tencent-privacypolicy'},
        {fw:'移动安全联盟 ',md:'用于获取用户唯一标识', xx:'唯一设备识别码(OAID) 用于获取用户唯一标识 设备识别码 嵌入该SDK，SDK将传输个人信息',qj:'设备识别码',fs:'嵌入该SDK，SDK将传输个人信息',lj:'http://www.msa-alliance.cn/'},
        {fw:'360加固',md:'APP应用加固', xx:'OAID或设备标识符的MD5，崩溃日志分析，应用盗版监测，加固数据分析',qj:'APP应用加固',fs:'嵌入该SDK，SDK将传输个人信息',lj:'https://jiagu.360.cn/#/app/sericuty/protect-record?id=14779980'},
        {fw:'网易易盾SDK',md:'用于风险控制和反欺诈，以保障账户和交易安全', xx:'设备MAC地址、蓝牙MAC地址、SIM卡信息、唯一设备识别码、安装软件列表、Android ID 、设备名称内存基本配置信息、系统及软件相关配置信息、WIFI及蜂窝网络信息',qj:'账户安全',fs:'嵌入该SDK，SDK将传输个人信息',lj:'https://dun.163.com/clause/privacy '},

      ]
    }
  },
  mounted() {
    let dark = this.$route.query.dark; // John
    let html = document.documentElement
    if (dark=='true') {
      html.classList.add('dark')
    } else {
      html.classList.remove('dark')
    }
  }
}
</script>

<style src="../../../../assets/css/agreement.css">

</style>
