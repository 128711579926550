<template>
  <div class="agreement_css">
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <title>充值协议</title>
    <header>
      <section class="agreementHeader">
        <h3>充值协议</h3>
      </section>
    </header>
    <section>
      <section>
        <article>
          <h2 style="font-weight: 330">“陌趣漂流瓶” 按照本协议的规定及不时发布的操作规则提供基于互联网的充值服务(以下称“充值服务”)，为获得充值服务，服务使用人(以下称“用户”或“您”)同意本协议的全部条款并按照页面上的提示完成全部的充值程序。用户为使用“陌趣漂流瓶”服务而进行充值即视为用户完全接受本协议项下的全部条款。用户在充值之前，应仔细阅读本协议，尤其是重点提示条款，如用户不同意本充值协议及/或对其的修改，请停止使用“陌趣漂流瓶”提供的服务。</h2>
          <h2  style="font-weight: 330">  未成年用户特别提示：未成年用户请在监护人的陪同下阅读本协议，使用我们的产品和服务必须事先取得监护人的同意。“陌趣漂流瓶”不鼓励未成年用户使用相关服务，未成年用户请委托监护人操作或在监护人明示同意下操作，否则不得使用本充值服务。</h2>
          <h2  style="font-weight: 330">监护人特别提示：您的被监护人在使用“陌趣漂流瓶”软件及相关服务时可能使用充值功能。您作为监护人，请保管好您的支付设备、支付账户及支付密码等，以避免被监护人在未取得您同意的情况下通过您的“陌趣漂流瓶”账号使用充值功能。</h2>
          <h2  style="font-weight: 330">未成年人用户及其监护人理解并确认，如您违反法律法规、本协议内容，则您及您的监护人应依照法律规定承担因此而可能导致的全部法律责任。</h2>
        </article>
        <article>
          <h2>1、关于虚拟宝石</h2>
          <p>虚拟宝石是“陌趣漂流瓶”向您提供的用于在其社交平台上进行相关消费的虚拟货币，可以用“宝石”购买虚拟礼物、虚拟道具等本平台各项产品或服务。用户了解及确认，用户购买及使用“宝石”的平台范围以本平台认可的范围为准。</p>
          <p>1.2 1元人民币可以兑换100宝石，宝石可用于兑换本平台各项产品或服务，能兑换，不能进行转账交易。</p>
          <p>1.3 本平台有权根据相关法律规定、主管部门要求、业务开展情况等因素单方面变更该兑换比例。</p>
        </article>
        <article>
          <h2>2、服务内容</h2>
          <p>用户使用相关平台进行充值，可以为“陌趣漂流瓶”账号充值宝石。</p>
        </article>
        <article>

          <h2>3、操作方法</h2>
          <p>3.1 用户可以在此充值系统上自由选择具体的充值方式，并按相应页面提示的程序完成充值。</p>
          <p>3.2 用户使用各种充值方式进行充值后，应保留充值订单号或充值卡以作为今后发生问题核对依据凭证（用户就充值服务投诉，但未有任何充值卡或其他有效凭证佐证的，“陌趣漂流瓶”将不会作出任何补偿或赔偿）。</p>
        </article>
        <article>
        <h2>4、风险提示</h2>

        <p>
          4.1 在使用充值方式时，用户务必仔细确认自己的帐号并仔细选择相关操作选项。若因为用户自身输入帐号错误、操作不当或不了解充值计费方式等因素造成充错帐号、错选充值种类等情形而损害自身权益的，“陌趣漂流瓶”将不会作任何补偿或赔偿。
        <p>
        4.2 若用户以非法的方式，或使用非“陌趣漂流瓶”所指定的充值方式进行充值，“陌趣漂流瓶”不保证该充值顺利或者正确完成。若因此造成用户权益受损时，“陌趣漂流瓶”不会作任何补偿或赔偿，“陌趣漂流瓶”同时保留随时终止该用户“陌趣漂流瓶”账号及使用各项充值服务的权利。
        <p>4.3 用户不得通过“陌趣漂流瓶”充值进行任何违法犯罪活动，否则“陌趣漂流瓶”有权终止服务，情节严重的，依法移交司法机关。</p>
        </article>
        <article>
        <h2> 5、充值成功后</h2>
          <p>充值成功后，用户可按照相关平台规则使用充值账号内的“宝石”。一旦充值成功，除法律规定的情形外，平台不会就账户内的“宝石”余额提供任何退还或逆向兑换（即“宝石”兑换为人民币或其他货币）服务。</p>
        </article>
        <article>
        <h2>6、渠道商服务手续费用</h2>
        <p>各充值方式对应的渠道商可能会按其标准制定相关充值渠道手续费用（可能会在充值操作页面或渠道商其他页面上提示），并在用户充值时收取。“陌趣漂流瓶”特此提醒：请用户务必注意各充值方式的渠道商服务手续费，并按自己的需求选取充值方式。</p>
        </article>
        <article>
        <h2>7、若因“陌趣漂流瓶”充值方式原因造成用户充值失实并经查证属实的，“陌趣漂流瓶”将根据用户充值情况作出变动措施：</h2>

        <p> 7.1 因充值方式原因，造成系统充值额小于用户实际充值额的，“陌趣漂流瓶”予以补其差额；</p>
        <p>7.2 因充值方式原因，造成系统充值额大于用户实际充值额的，“陌趣漂流瓶”有权追回差额；</p>
        <p>7.3 因渠道商收取服务手续费用等原因造成系统充值额小于用户实际充值额的，不属于用户充值失实。</p>
        </article>
        <article>
        <h2>8、服务变更、中断或终止</h2>

        <p> 8.1 “陌趣漂流瓶”可以根据业务需求随时变更服务或更新本协议的条款，并会在相关的页面上及时提示修改内容。修改后的服务协议一旦在页面上公布即代替原来的服务协议。</p>
        <p> 8.2 如因系统维护或升级的需要而需暂停网络充值服务，“陌趣漂流瓶”将尽可能事先进行通告</p>
        <p> 8.3 如发生下列任何一种情形，“陌趣漂流瓶”有权随时中断或终止向用户提供本协议项下的网络服务而无需通知用户：</p>
        <p> (a) 用户提供的个人资料不真实；</p>
        <p> (b) 用户违反本协议中规定的使用规则。</p>
        <p>8.4 除前款所述情形外，“陌趣漂流瓶”同时保留在不事先通知用户的情况下随时中断或终止部分或全部网络充值服务的权利，对于充值服务的中断或终止而造成的任何损失，“陌趣漂流瓶”无需对用户或任何第三方承担任何责任。</p>
        </article>
        <article>
          <h2>9、 免责声明和损害赔偿</h2>

        <p> 9.1发生下列情况时，“陌趣漂流瓶”免于承担任何法律责任：</p>
        <p>（a）用户因使用第三方支付渠道充值过程中发生的相关争议；</p>
        <p> （b）由于用户将密码告知他人导致的用户财产损失；</p>
        <p>（c）因用户个人故意或重大过失，或本协议之外的第三方所造成的用户财产损失；</p>
        <p> （d）由不可抗力及不可预见的情势导致的各种情况和纠纷；不可抗力和不可预见情势包括：黑客攻击、电信部门技术调整导致重大影响、政府管制导致的暂时关闭、病毒侵袭。</p>
        <p>9.2 用户同意保障和维护“陌趣漂流瓶”及其他用户的利益，如因用户违反有关法律、法规或本协议项下的任何条款而给“陌趣漂流瓶”或任何第三人造成损失，用户同意承担由此造成的损害赔偿责任。</p>
        </article>
        <article>
          <h2>10、其他</h2>
          <p> 10.1 本协议的订立、执行和解释及争议的解决均应适用中国法律，不适用冲突法。</p>
          <p> 10.2本协议签订地为中国江苏省连云港市海州区。若您与“陌趣漂流瓶”之间发生任何争议或纠纷，双方应友好协商；如协商不成，您同意将争议提交本协议签订地有管辖权的人民法院诉讼解决。</p>
          <p> 10.3本协议项下所有的通知均可通过重要页面公告、电子邮件传送等方式进行；该等通知于公告或发送之日视为已送达收件人。</p>
          <p> 10.4本协议的条款无论因何种原因导致部分无效，其余条款仍然有效，对双方具有约束力。本协议任何条款的放弃均不应被视为对该等条款或任何其他条款的进一步或继续放弃，“陌趣漂流瓶”在本协议项下未主张的任何权利或条款，不构成对该权利或条款的放弃。</p>
        </article>
      </section>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Recharge',
  mounted() {
    let dark = this.$route.query.dark; // John
    let html = document.documentElement
    if (dark=='true') {
      html.classList.add('dark')
    } else {
      html.classList.remove('dark')
    }
  }
}
</script>
