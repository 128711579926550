<template>
  <div class="main_body_newStore">
    <div class="main_head_newStore">
      <img src="../../../../assets/image/store/store_red.png" width="95%"
           style="position: absolute;margin: auto;left: 0;right: 0;top: 2.5rem">
      <div style="margin-top:5.6rem;">
        <img src="../../../../assets/image/store/go_market.png" @click="goMarket()" width="65%"
             style="position: absolute;margin: auto;left: 0;right: 0;">
        <img src="../../../../assets/image/store/go_cs.png" @click="goAward()" width="65%"
             style="position: absolute;margin: auto;left: 0;right: 0;margin-top: 1rem">
        <div class="store_gz">

          <span style="color: rgba(67, 139, 249, 100);font-size:0.35rem;font-weight: bold">{{ title }}</span>

          <p v-html="content" class="store_content">
          </p>
        </div>
        <span class="store_bq">本活动最终解释权归官方所有</span>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "活动规则",
      content: "<br>1.任务奖励：100宝石（宝石可用于赠送礼物、兑换、消耗使用）<br>" +
          "2.任务奖励：每台设备/账号只能参与一次，在手机应用商店搜索【陌趣漂流瓶】，进入评论区进行五星+文字好评并截图<br>" +
          "3.领取方式：点击上方上传好评截图按钮，发送好评截图即可领取！"
    }
  },

  // 方法
  methods: {
    // 跳转客服
    goAward() {
      window.android.getAward();
    },
    // 跳转应用市场
    goMarket() {
      window.android.goMarket();
    },

  }
}
</script>

<style>
.main_body_newStore {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  height: 0;
  padding-bottom: 240%;
  /*background: -webkit-linear-gradient(top,rgba(130, 20, 192, 1), rgba(31, 10, 118, 1)) no-repeat;*/
  background-size: 100%;
}

.main_head_newStore {
  width: 100%;
  height: 0;
  padding-bottom: 105%;
  background-image: url('../../../../assets/image/store/store_head.png');
  background-size: 100%;
}

.store_bq {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  z-index: 2;
  top: 13.8rem;
  text-align: center;
  opacity: 0.8;
  color: rgba(51, 51, 51, 100);
  font-size: 0.25rem;
  font-weight: bold
}

.store_content {
  line-height: 0.5rem;
  color: rgba(67, 139, 249, 100);
  font-size: 0.25rem;
}

.store_gz {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  padding: 0.3rem 0.3rem 0.4rem 0.3rem;
  border-radius: 0.2rem;
  width: 80%;
  background: rgba(239, 250, 255, 1);
  top: 8.5rem
}
</style>
