import Router from 'vue-router'
import Help from '@/view/mq/app/Help'
import ContentHelp from '@/view/mq/app/ContentHelp'
import CleanNet from '@/view/mq/app/CleanNet'
import Privacy from '@/view/mq/app/agreement/Privacy'
import Vip from '@/view/mq/app/agreement/Vip'
import User from '@/view/mq/app/agreement/User'
import Recharge from '@/view/mq/app/agreement/Recharge'
import Store from '@/view/mq/app/store/index'
import Cash from '@/view/mq/app/Cash'
export default new Router({
  mode: 'history',
  routes: [
    //帮助中心主菜单
    {
      path: '/help',
      name: 'Help',
      component: Help,
      meta: {
        title: '帮助中心'
      }
    },
    //帮助中心内容
    {
      path: '/contentHelp',
      name: 'ContentHelp',
      component: ContentHelp,
    },
    //净网公约
    {
      path: '/cleanNet',
      name: 'CleanNet',
      component: CleanNet,
      meta: {
        title: '详情',
        keepAlive: true
      }
    },
    //隐私协议
    {
      path: '/privacy',
      name: 'Privacy',
      component: Privacy,
      meta: {
        title: '隐私协议',
        keepAlive: true
      }
    },
    //用户协议
    {
      path: '/user',
      name: 'User',
      component: User,
      meta: {
        title: '用户协议'
      },
    },
    //充值协议
    {
      path: '/recharge',
      name: 'Recharge',
      component: Recharge,
      meta: {
        title: '充值协议'
      },
    },
    {
      path: '/cash',
      name: 'Cash',
      component: Cash,
      meta: {
        title: '兑换说明'
      },
    },
    //好评
    {
      path: '/store',
      name: 'store',
      component: Store,
      meta: {
        title: '详情'
      },
    },
    //会员协议
    {
      path: '/vip',
      name: 'Vip',
      component: Vip,
      meta: {
        title: '会员协议'
      },
    },
  ]
})
