<template>
  <div>
    <div class="app_cash_css" >
      {{title }}
    </div>
    <div style="margin-left:16px;font-size: 0.3rem;line-height: 0.3rem;">
      <p v-html="content">
      </p>
    </div>
    <div class="app_cash_css" >
      {{title1 }}
    </div>
    <div style="margin-left:  16px;font-size: 0.3rem;line-height: 0.3rem;">
      <p v-html="content1">
      </p>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Cash',
  data () {
    return {
      title: "兑换说明",
      content: "<br>1.填写正确的支付宝账号和支付宝名称（真实姓名）。<br>" +
          "2.收到礼物时，可以获得礼物价格的七成收益。<br>" +
          "3.聊天或者收取礼物获得的宝石可用于提现。<br>" +
          "4.提现宝石数量最低220，单次最高只可提现1000¥，无次数限制。" +
          "<br>5.提交成功后将在24小时内到账，若账号处于违规期间，提现功能将被冻结。兑换账单中可以查看提现状态及失败原因。<br>" +
          "6.若账号存在违规行为，平台有权根据用户协议拒绝该账号的提现申请，永久封禁账号禁止提现。",
      title1: "兑换比例",
      content1: "<br>220~2300时，比例220:1<br>" +
          "2300~5000时，比例200:1<br>" +
          "5000~10000时，比例190:1<br>" +
          "10000~20000时，比例180:1<br>" +
          ">20000时，比例170:1"
    }
  },
  mounted() {
    let dark = this.$route.query.dark; // John
    let html = document.documentElement
    if (dark=='true') {
      html.classList.add('dark')
    } else {
      html.classList.remove('dark')
    }
  }
}
</script>

