<template>
  <div class="agreement_css">
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <title>用户协议</title>
    <header>
      <section class="agreementHeader">
        <h3>用户协议</h3>
      </section>
    </header>
    <section>
      <section>
        <article>
          <h2 style="font-weight: 600">特别提示<br>“陌趣漂流瓶”的运营主体  连云港万维网络科技有限公司（以下简称“我们”或“陌趣”）在此特别提醒您（用户）在注册成为用户之前，请认真阅读本《用户协议》（以下简称“协议”），确保您充分理解本协议中各条款。请您审慎阅读并选择接受或不接受本协议。除非您接受本协议所有条款，否则您无权注册、登录或使用本协议所涉服务。您的注册、登录、使用等行为将视为对本协议的接受，并同意接受本协议各项条款的约束。
            <br>本协议约定“陌趣”与用户之间关于“陌趣”软件服务（以下简称“服务”）的权利义务。“用户”是指注册、登录、使用本服务的个人。在“陌趣”修改协议条款后，如果用户不接受修改后的条款，请立即停止使用“陌趣”提供的服务，用户继续使用“陌趣”提供的服务将被视为接受修改后的协议。
          </h2>
        </article>
        <article>
          <h2>一、帐号注册</h2>
          <p>1、用户在使用本服务前需要注册一个“陌趣”账号。“陌趣”账号应当使用手机号码注册或绑定，请用户使用尚未与“陌趣”账号绑定的手机号码，以及未被“陌趣”根据本协议封禁的手机号码。</p>
        </article>
        <article>
          <h2>二、服务内容</h2>
          <p>1、本服务的具体内容由“陌趣”根据实际情况提供，包括但不限于授权用户通过其账号进行消息聊天、添加好友、加入群组、等功能及服务。“陌趣”可以对其提供的服务予以变更，且“陌趣”提供的服务内容可能随时变更;</p>
          <p>
            2、“陌趣”提供的服务包含免费服务与收费服务。用户可以通过付费方式购买收费服务，具体方式为：用户通过支付宝或其他“陌趣”平台提供的付费途径支付一定数额的人民币购买“陌趣”平台的虚拟货币——宝石，然后根据“陌趣”公布的资费标准以宝石购买用户欲使用的收费服务，从而获得收费服务使用权限。对于收费服务，“陌趣”会在用户使用之前给予用户明确的提示，只有用户根据提示确认其同意按照前述支付方式支付费用并完成了支付行为，用户才能使用该等收费服务。支付行为的完成以银行或第三方支付平台生成“支付已完成”的确认通知为准。
          </p>
        </article>
        <article>
          <h2>三、用户个人信息保护</h2>
          <p>1、“陌趣”与用户一同致力于用户个人信息的保护。</p>
          <p>
            2、在使用“陌趣”软件及相关服务的过程中，用户可能需要提供用户的个人信息（包括但不限于用户的电话号码、头像信息、设备信息等），以便“陌趣”向用户提供更好的服务和相应的技术支持。“陌趣”将依法保护用户浏览、修改、删除相关个人信息以及撤回授权的权利，并将运用加密技术、匿名化处理等其他与“陌趣”软件及相关服务相匹配的技术措施及其他安全措施保护用户的个人信息。</p>
          <p>3、“陌趣”重视对未成年人的保护。“陌趣”将依赖用户提供的个人信息判断用户是否为未成年人。任何18岁以下的未成年人均不得注册账号或使用本服务。</p>
          <p>4、更多关于用户个人信息保护的内容，请参看《“陌趣”隐私政策》。</p>
        </article>
        <article>
          <h2>四、内容规范</h2>
          <p>1、本条所述内容是指用户使用本服务过程中所发布的任何内容，包括但不限于账号头像、名称、个人签名等注册信息及认证资料，或文字聊天内容和相关链接页面，以及其他使用账号或本服务所产生的内容。</p>
          <p>2、用户不得利用“陌趣”账号或本服务发布如下法律、法规和政策禁止的内容：</p>
          <p>(1) 反对宪法所确定的基本原则的;</p>
          <p>(2) 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的;</p>
          <p>(3) 损害国家荣誉和利益的；</p>
          <p>(4) 煽动民族仇恨、民族歧视，破坏民族团结的；</p>
          <p>(5) 破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
          <p>(6) 散布谣言，扰乱社会秩序，破坏社会稳定的；</p>
          <p>(7) 散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</p>
          <p>(8) 侮辱或者诽谤他人，侵害他人合法权益的；</p>
          <p>(9) 不遵守法律法规底线、社会主义制度底线、国家利益底线、公民合法权益底线、社会公共秩序底线、道德风尚底线和信息真实性底线的“七条底线”要求的；</p>
          <p>(10) 含有法律、行政法规禁止的其他内容的信息。</p>
          <p>3、用户不得利用“陌趣”账号或本服务发布如下干扰“陌趣”正常运营，以及侵犯其他用户或第三方合法权益的内容：</p>
          <p>(1) 含有任何性或性暗示的；</p>
          <p>(2) 含有辱骂、恐吓、威胁内容的；</p>
          <p>(3) 含有骚扰、垃圾广告、恶意信息、诱骗信息的；</p>
          <p>(4) 涉及他人隐私、个人信息或资料的；</p>
          <p>(5) 侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的；</p>
          <p>(6) 含有其他干扰本服务正常运营和侵犯其他用户或第三方合法权益内容的信息。</p>

        </article>
        <article>

          <h2>五、使用规则</h2>
          <p>1、用户在本服务中或通过本服务所传送、发布的任何内容并不反映或代表，也不得被视为反映或代表“陌趣”的观点、立场或政策，“陌趣”对此不承担任何责任。</p>
          <p>2、用户不得利用“陌趣”账号或本服务进行如下行为：</p>
          <p>(1) 提交、发布虚假信息，或盗用他人头像或资料，冒充、利用他人名义的；</p>
          <p>(2) 强制、诱导其他用户关注、点击链接页面或分享信息的；</p>
          <p>(3) 虚构事实、隐瞒真相以误导、欺骗他人的；</p>
          <p>(4) 利用技术手段批量建立虚假帐号的；</p>
          <p>(5) 利用“陌趣”账号或本服务从事任何违法犯罪活动的；</p>
          <p>(6) 制作、发布与以上行为相关的方法、工具，或对此类方法、工具进行运营或传播，无论这些行为是否为商业目的；</p>
          <p>(7) 其他违反法律法规规定、侵犯其他用户合法权益、干扰“陌趣”正常运营或“陌趣”未明示授权的行为。</p>
          <p>3、用户须对利用“陌趣”账号或本服务传送信息的真实性、合法性、无害性、准确性、有效性等全权负责，与用户所传播的信息相关的任何法律责任由用户自行承担，与“陌趣”无关。</p>
          <p>4、“陌趣”提供的服务中可能包括广告，用户同意在使用过程中显示“陌趣”和第三方供应商、合作伙伴提供的广告。除法律法规明确规定外，用户应自行对依该广告信息进行的交易负责。</p>
          <p>5、除非“陌趣”书面许可，用户不得从事下列任一行为：</p>
          <p>(1) 删除软件及其副本上关于著作权的信息；</p>
          <p>(2) 对软件进行反向工程、反向汇编、反向编译，或者以其他方式尝试发现软件的源代码；</p>
          <p>(3) 对“陌趣”拥有知识产权的内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等；</p>
          <p>(4)
            对软件或者软件运行过程中释放到任何终端内存中的数据、软件运行过程中客户端与服务器端的交互数据，以及软件运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非经“陌趣”授权的第三方工具/服务接入软件和相关系统；</p>
          <p>(5) 通过修改或伪造软件运行中的指令、数据，增加、删减、变动软件的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的；</p>
          <p>(6) 通过非“陌趣”开发、授权的第三方软件、插件、外挂、系统，登录或使用“陌趣”软件及服务，或制作、发布、传播非“陌趣”开发、授权的第三方软件、插件、外挂、系统。</p>
        </article>
        <article>

          <h2>六、虚拟货币</h2>
          <p>
            1、“陌趣”将在“陌趣”平台发行虚拟货币，即宝石。宝石可用于购买“陌趣”平台的增值服务，包括但不限虚拟礼物服务，除此外，不得用于其他任何用途。该等增值服务的价格均以宝石为单位，具体价格信息将由“陌趣”自行决定并在相关服务页面上显示。</p>
          <p>2、宝石和人民币的兑换比例依用户购买渠道的不同而有不同的兑换比例，具体兑换比例以用户购买宝石相关渠道服务页面显示为准。“陌趣”有权根据运营情况随时变更上述兑换比例，并将在用户购买宝石相关渠道服务页面显示。</p>
          <p>3、用户默认已开通宝石账户，可进行宝石购买（下称“充值”）和消费。</p>
          <p>
            4、用户可以通支付宝或其他“陌趣”平台提供的充值途径为宝石账户进行充值。用户确认不会以非法方式或者使用非平台所指定的充值途径进行充值,如果用户违规使用非“陌趣”认可的充值途径非法充值/购买宝石，则“陌趣”不保证充值顺利或正确完成，若因此造成用户权益受损，“陌趣”将不会作出任何补偿或赔偿，“陌趣”同时保留随时终止用户“陌趣”账号资格及使用各项充值服务的权利，并进行相应惩罚。</p>
          <p>
            5、用户确认在进行充值前已经仔细确认过自己的账号并仔细选择了相关操作选项，若因用户自身输入账号错误、操作不当或不了解充值计费方式等因素造成充错账号、错选充值种类等情形而损害自身权益的，“陌趣”将不会作出任何补偿或赔偿。</p>
          <p>6、用户确认，宝石一经充值成功，除法律法规明确规定外，在任何情况下不能兑换为法定货币，不能转让他人。除法律法规明确规定外，宝石账户充值完成后，“陌趣”不予退款。</p>
          <p>
            7、用户确认，宝石只能用于购买“陌趣”平台上的各类增值服务，任何情况下不得与“陌趣”以外的第三方进行宝石交易，亦不得在除“陌趣”平台以外的第三方平台（如淘宝）上进行交易；如违反前述约定，造成用户或第三方任何损失，“陌趣”不负任何责任，且如“陌趣”有理由怀疑用户的宝石账户或使用情况有作弊或异常状况，“陌趣”将拒绝该用户使用宝石进行支付，直至按本协议约定采取相关封禁措施。</p>
          <p>8、“陌趣”有权基于交易安全等方面的考虑不时设定或修改涉及交易的相关事项，包括但不限于交易限额、交易次数等。用户了解并确认“陌趣”的前述设定或修改可能对用户的交易产生一定的不便，用户对此没有异议。</p>
          <p>9、用户确认，除法律法规明确规定或本协议另有约定外，用户已购买的任何收费服务不能以任何理由退购（即退换成宝石或法定货币）或调换成其他服务。</p>
          <p>10、因用户自身的原因导致“陌趣”无法提供宝石购买服务或提供宝石购买服务时发生任何错误而产生的任何损失或责任，由用户自行负责，包括但不限于：</p>
          <p>(1)因用户的陌趣账号丢失、被封禁或冻结；</p>
          <p>(2)用户将密码告知他人导致的财产损失；</p>
          <p>(3)因用户绑定的第三方支付机构账户的原因导致的任何损失或责任；</p>
          <p>(4)其他用户故意或者重大过失或者违反法律法规导致的财产损失。</p>
          <p>
            11、用户在使用“陌趣”提供的服务时，如出现违反国家法律法规、本协议约定或其他本平台对用户的管理规定的情形，“陌趣”有权暂时或永久封禁用户的账号。账号封禁后至解禁（如有）前，用户账户上的剩余宝石将被暂时冻结或全部扣除，不可继续用于购买平台上的虚拟产品或服务，同时不予返还用户购买宝石时的现金价值。</p>
          <p>
            12、用户确认并同意如用户主动注销账号，则用户需在注销前将已充值到账的宝石及/或虚拟礼物收益消耗完毕，如您没有消耗完毕且继续注销账号，则您已充值到账的宝石及/或虚拟礼物收益等视为自动放弃，“陌趣”不予返还相应的现金价值，也不会作出任何补偿。</p>
          <p>13、如依据中国税收相关法律法规的规定需要由“陌趣”为您代扣代缴个人所得税的，“陌趣”将在您产生收益当月为您申报并代为扣缴相关税费。</p>
        </article>

        <article>

          <h2>七、账户管理</h2>
          <p>1、 “陌趣”账号的所有权归“陌趣”所有，用户完成申请注册手续后，获得“陌趣”账号的使用权，该使用权仅属于初始申请注册人，禁止赠与、借用、租用、转让或售卖。</p>
          <p>2、用户可以通过“我的”页面来查询、更改、删除、注销“陌趣”账户上的个人资料、注册信息等，但需注意，删除有关信息的同时也会删除用户储存在系统中的文字和图片。用户需承担该风险。</p>
          <p>
            3、用户有责任妥善保管注册账号信息及账号密码的安全，因用户保管不善可能导致遭受盗号或密码失窃，责任由用户自行承担。用户需要对注册账号以及密码下的行为承担法律责任。用户同意在任何情况下不使用其他用户的账号或密码。在用户怀疑他人使用其账号或密码时，用户同意立即通知“陌趣”。</p>
          <p>4、用户应遵守本协议的各项条款，正确、适当地使用本服务，如因用户违反本协议中的任何条款，“陌趣”在通知用户后有权依据协议中断或终止对违约用户“陌趣”账号提供服务。</p>
          <p>
            5、用户可以通过“设置”-“账号与安全”-“账号注销”页面里来进行账号注销服务，用户确认注销账号是不可恢复的操作，用户应自行备份与“陌趣”账号相关的信息和数据，用户确认操作之前与“陌趣”账号相关的所有服务均已进行妥善处理。用户确认并同意注销账号后并不代表本“陌趣”账号注销前的账号行为和相关责任得到豁免或减轻，如在注销期间，用户的账号被他人投诉、被国家机关调查或者正处于诉讼、仲裁程序中，“陌趣”有权自行终止用户的账号注销并无需另行得到用户的同意。</p>
        </article>
        <article>

          <h2>八、数据储存</h2>
          <p>
            1、用户在本服务中的相关数据将以加密方式存储在中国境内，“陌趣”在用户删除或注销后，将立即删除数据或按相关法律法规的规定进行匿名化处理，但按相关法律法规或司法机关的要求需要延长保存时间的除外。在延长保存期间内，“陌趣”会以加密形式安全地存储相关数据并进行隔离，直至按相关法律法规或司法机关的要求可以删除或匿名化处理。</p>
          <p>2、如用户停止使用本服务或本服务终止，“陌趣”可以从服务器上永久地删除用户的数据。本服务停止、终止后，“陌趣”没有义务向用户返还任何数据。</p>
        </article>
        <article>

          <h2>九、风险承担</h2>
          <p>
            1、用户理解并同意，“陌趣”仅为用户提供信息分享、传送及获取的平台，用户必须为自己注册账号下的一切行为负责，包括用户所传送的任何内容以及由此产生的任何后果。用户应对“陌趣”及本服务中的内容自行加以判断，并承担因使用内容而引起的所有风险，包括因对内容的正确性、完整性或实用性的依赖而产生的风险。
            <br> 如果用户发现任何人违反本协议约定或以其他不当的方式使用本服务，请立即向陌趣漂流瓶举报或投诉，陌趣漂流瓶将依本协议约定进行处理。</p>
          <p>2、用户理解并同意，因业务发展需要，“陌趣”保留单方面对本服务的全部或部分服务内容变更、暂停、终止或撤销的权利，用户需承担此风险。</p>
        </article>

        <article>

          <h2>十、知识产权声明</h2>
          <p>
            1、除本服务中涉及广告的知识产权由相应广告商享有外，“陌趣”在本服务中提供的内容（包括但不限于网页、文字、图片、音频、视频、图表等）的知识产权均归“陌趣”所有，但用户在使用本服务前对自己发布的内容已合法取得知识产权的除外。</p>
          <p>2、除另有特别声明外，“陌趣”提供本服务时所依托软件的著作权、专利权及其他知识产权均归“陌趣”所有。</p>
          <p>
            3、“陌趣”在本服务中所涉及的图形、文字或其组成，以及其他“陌趣”标志及产品、服务名称（以下统称““陌趣”标识”），其著作权或商标权归“陌趣”所有。未经“陌趣”事先书面同意，用户不得将“陌趣”标识以任何方式展示或使用或作其他处理，也不得向他人表明用户有权展示、使用、或其他有权处理“陌趣”标识的行为。</p>
          <p>4、上述及其他任何“陌趣”或相关广告商依法拥有的知识产权均受到法律保护，未经“陌趣”或相关广告商书面许可，用户不得以任何形式进行使用或创造相关衍生作品。</p>

        </article>
        <article>

          <h2>十一、法律责任</h2>
          <p>1、如果“陌趣”发现或收到他人举报或投诉用户违反本协议约定的，“陌趣”有权不经通知随时对相关内容，包括但不限于用户资料进行审查、删除，并视情节轻重对违规账号处以包括但不限于警告、账号封禁 、设备封禁
            、功能封禁的处罚，且通知用户处理结果。</p>
          <p>
            2、用户理解并同意，“陌趣”有权依合理判断对违反有关法律法规或本协议规定的行为进行处罚，对违法违规的任何用户采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，用户应承担由此而产生的一切法律责任。</p>
          <p>3、用户理解并同意，因用户违反本协议约定，导致或产生的任何第三方主张的任何索赔、要求或损失，包括合理的律师费，用户应当承担赔偿责任。</p>

        </article>
        <article>

          <h2>十二、不可抗力及其他免责事由</h2>
          <p>
            1、用户理解并确认，在使用本服务的过程中，可能会遇到不可抗力等风险因素，使本服务发生中断。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、瘟疫流行和风暴等以及社会事件如战争、动乱、政府行为等。出现上述情况时，“陌趣”将努力在第一时间与相关单位配合，及时进行修复，但是由此给用户或第三方造成的损失，“陌趣”及合作单位在法律允许的范围内免责。</p>
          <p>
            2、本服务同大多数互联网服务一样，受包括但不限于用户原因、网络服务质量、社会环境等因素的差异影响，可能受到各种安全问题的侵扰，如他人利用用户的资料，造成现实生活中的骚扰；用户下载安装的其它软件或访问的其他网站中含有“特洛伊木马”等病毒，威胁到用户的计算机信息和数据的安全，继而影响本服务的正常使用等等。用户应加强信息安全及使用者资料的保护意识，要注意加强密码保护，以免遭致损失和骚扰。</p>
          <p>
            3、用户理解并确认，本服务存在因不可抗力、计算机病毒或黑客攻击、系统不稳定、用户所在位置、用户关机以及其他任何技术、互联网络、通信线路原因等造成的服务中断或不能满足用户要求的风险，因此导致的用户或第三方任何损失，“陌趣”不承担任何责任。</p>
          <p>4、用户理解并确认，“陌趣”需要定期或不定期地对“陌趣”平台或相关的设备进行检修或者维护，如因此类情况而造成服务在合理时间内的中断，“陌趣”无需为此承担任何责任，但“陌趣”应事先进行通告。</p>
          <p>5、“陌趣”依据法律法规、本协议约定获得处理违法违规或违约内容的权利，该权利不构成“陌趣”的义务或承诺，“陌趣”不能保证及时发现违法违规或违约行为或进行相应处理。</p>
          <p>
            6、在任何情况下，“陌趣”均不对任何间接性、后果性、惩罚性、偶然性、特殊性或刑罚性的损害，包括因用户使用“陌趣”或本服务而遭受的利润损失，承担责任（即使“陌趣”已被告知该等损失的可能性亦然）。尽管本协议中可能含有相悖的规定，“陌趣”对用户承担的全部责任，无论因何原因或何种行为方式，始终不超过用户因使用“陌趣”提供的服务而支付给“陌趣”的费用(如有)。</p>
        </article>
        <article>

          <h2>十三、服务的变更、中断、终止</h2>
          <p>1、鉴于网络服务的特殊性，用户同意“陌趣”有权随时变更、中断或终止部分或全部的服务（包括收费服务）</p>
          <p>2、如发生下列任何一种情形，“陌趣”有权变更、中断或终止向用户提供的免费服务或收费服务，而无需对用户或任何第三方承担任何责任：</p>
          <p>(1) 根据法律规定用户应提交真实信息，而用户提供的个人资料不真实、或与注册时信息不一致又未能提供合理证明；</p>
          <p>(2) 用户违反相关法律法规或本协议的约定；</p>
          <p>(3) 按照法律规定或有权机关的要求；</p>
          <p>(4) 出于安全的原因或其他必要的情形。</p>

        </article>
        <article>

          <h2>十四、其他</h2>
          <p>1、“陌趣”郑重提醒用户注意本协议中免除“陌趣”责任和限制用户权利的条款，请用户仔细阅读，自主考虑风险。</p>
          <p>2、本协议的效力、解释及纠纷的解决，适用于中华人民共和国法律。若用户和“陌趣”之间发生任何纠纷或争议，首先应友好协商解决，协商不成的，用户同意将纠纷或争议提交协议签署地所在的人民法院管辖。</p>
          <p>3、本协议的任何条款无论因何种原因无效或不具可执行性，其余条款仍有效，对双方具有约束力。</p>
          <p>
            4、由于互联网高速发展，您与“陌趣”签署的本协议列明的条款可能并不能完整罗列并覆盖您与“陌趣”所有权利与义务，现有的约定也不能保证完全符合未来发展的需求。因此，“陌趣”隐私权政策等均为本协议的补充协议，与本协议不可分割且具有同等法律效力。如您使用陌趣平台服务，视为您同意上述补充协议。</p>
        </article>
      </section>
    </section>
  </div>
</template>

<script>
export default {

  name: 'User',
  mounted() {
    let dark = this.$route.query.dark; // John
    let html = document.documentElement
    if (dark=='true') {
      html.classList.add('dark')
    } else {
      html.classList.remove('dark')
    }
  }
}
</script>
