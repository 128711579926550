<template>
  <div class="agreement_css">
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <title>会员协议</title>
    <header>
    </header>
    <section class="agreement-body">
      <article>
        <p style="font-weight: 600"><span style="color: #ec6767;margin-top: 1rem"><br>发布日期：2023年11月16号<br>
             生效日期：2023年11月16号</span>
          <br><br>
          一、导言
          <br>
        <p>
          欢迎购买陌趣会员服务。通过购买陌趣会员功能，支付费用后，您就可以拥有并使用陌趣会员的专享功能。连云港万维网络科技有限公司在此特别提醒您，在开始购买会员之前，请务必阅读陌趣《会员服务协议》以下简称“本协议”!充分理解本协议中各项条款，特别是免除或限制责任的相应条款。</p>
        <p>
          本协议是连云港万维网络科技有限公司与“陌趣会员”之间关于用户成为陌趣会员并使用陌趣提供的会员服务所订立的协议，"陌趣会员"是指购买陌趣会员功能开享受陌趣提供的会员服务的用户。陌趣会员服务是有偿的增值服务，除非您(以下也称"陌趣会员"/“会员")同意并接受本协议所有条款内容，并完成付费程序，否则您无权使用陌趣会员专享功能及本协议中所涉及的其他各项服务。</p>
        <p>
          您在使用会员服务的同时应该遵守本协议、陌趣服务条款及前述相关协议、规则、声明、公告、政策、活动规则等所有条款及后续修改。若您不同意本协议中所述任何条款或其后对协议条款的修改，您可以选择不开通本会员服务或请立即停止使用会员服务，您获得并使用会员服务的行为包括但不限于点击购买、支付、使用等)将被视为已经仔细阅读。充分理解并毫无保留地接受本协议及后续修改的所有条款。在您开通会员后的会员有效期内，如陌趣对本协议做了修改，如果您不同意陌趣对本协议相关条款所做的修改，您应立即停止使用陌趣会员。如果您继续使用或续费，则视为您接受陌趣对本协议相关条款所做的修改。
        </p>
      </article>
      <article>
        <br>
        <p>
          特别提示:请您理解，陌趣仅允许18周岁以上的成年人注册，如果您是小于18周岁的未成年人，请您不要注册陌趣账户，不要使用陌趣会员服务。同时提醒，即使监护人完全同意本协议、陌趣服务条款及前述相关协议、规则、声明、公告、政策、活动规则等所有条款及后续修改的全部内容，监护人仍应当对未成年人的行为予以监管，确保未成年人不得擅自使用陌趣会员服务。</p>
      </article>
      <article>
        <h2>二、会员权益</h2>
        <p>陌趣会员用户除了可以使用陌趣普通注册用户拥有的功能外，还将享受多种专享的增值服务。陌趣会员所包含的具体会员种类/类型、会员权益及收费标准等以陌趣在陌趣会员页面公布的信息为准。</p>
        <p>
          2、陌趣有权根据业务发展情况调整全部或部分会员种类/类型、会员权益内容、服务内容及收费标准等。陌趣就前述调整将在相应会员服务页面进行通知或公告，您可以通过陌趣平台查询最新的会员规则。陌趣鼓励您定期查看本协议及会员规刚内容，以更好地保障您的权益。
        </p>
        <p> 3、当陌趣会员使用陌趣各单项功能/权益时，陌趣会员的使用行为视为其对各单项功能/权益的服务条款以及陌趣在该单项能/双益中发出的各类说明的同意。</p>
      </article>
      <article>
        <h2>三、会员服务期限、收费标准、购买及延续</h2>
        <p>1、陌趣会员服务提供会员专属权益服务均有有效期限，请您在购买会员时务必认真阅读会员规则内容或相关页面提示，您一旦购买会员即视为认可会员服务及各项专属权益的有效期限。</p>
        <p>
          2、陌趣会员服务的有效有限以会员自行选择并支付相应会员费用的页面展示期限为准。陌趣会员服务的有效期限到期后，陌趣将停止向您提供会员服务，会员专属权益也将立即失效。
        </p>
        <p>
          3、陌趣会员服务的收费标准由陌趣根据自身运营策略决定包括制定会员种类/类型、价格、以及不同会员种类/类型的权益内容等)，并在会员服务购买页面向您展示。您同意您继续操作的行为(包括但不限于点击同意购买、支付行为，继续使用会员服务的行为等)，自视为您知悉并同意该收费标准。
        </p>
        <p>
          4、会员到期再次购买说明:您的会员有效期限届满后，您可以选择再次购买会员服务，具体会员购买价格以届时会员购买页面展示的会员服务价格为准。
        </p>
        <p>
          5、陌趣用户完成用户注册程序，并通过陌趣平台提供的支付方式完成相应的服务付费后，即获得陌趣会员资格。您应通过陌趣官方接入、授权的支付渠道(包话但不限、支付宝、微信)等，具体购买方式以陌趣实际展示的为准。依约支付相应费用后购买陌趣会员/陌趣暂不支持您通过其他非官方渠道进行购买，也不支持您通过其他人的支付账户或其他人支付手段进行购买;对于非陌趣官方接入、授权的渠道或方式购买/获得(包括但不限于使用非法、违规、作弊等手段购买/获得)的陌趣会员将不被陌趣平台认可，此外，请知悉，违法违规的会员服务购买行为还可能引发盗号、诈骗等风险，陌趣不负责由此造成的相应损失，同时陌趣保留向您或其他责任人追究法律责任的权利。陌趣会员开通之后不能被租用、借用、转让或许可、告卖给第三方使用，不得以盈利、经营等非个人使用为目的为自己或他人开通陌趣会员。
        </p>
        <p>
          6、会员的延续:您的会员有效期届满后，您可以选择再次购买会员服务，具体方式

          在会员到期前，您需手动购买陌趣会员以继续享受会员专属权益服务:具体会员服务价格和服务内容，以会员手动续费时展示页面的内容为准。
        </p>
      </article>
      <article>
        <h2>四、会员的权利和义务</h2>

        <p>
          1、您承诺具备使用陌趣会员服务所必需的民事能力和合法资格，有权且有能力同意本协议、陌趣服务条款及前述相关协议、规则、声明、公告、政策、活动规则等所有条款及后续修改的全部内容。您在购买陌趣会员服务成为陌趣会员之前，须审阅并同意接受相关的服务条款。同时，您必须保证您所填写的个人信息真实、准确且有效，否则陌趣有权否认或撤消您的会员身份，同时不予退还或赔偿任何造成的陌趣会员相关服务费。您同意并承诺当个人资料发生变化时，会及时修改注册的个人资料，否则由此造成的会员权利不能全面有效行使的责任及其他后果由您自己承担。</p>
        <p>
          2、您不得以盗窃、利用系统漏洞等不正当手段获去陌趣会员资格，不得在未获陌趣授权的非法销售，非法转让会员的网站或其他渠道上获取或购买会员服务资格，不得将陌趣会员账号以出借、出租、出售等任何形式提供给第三方使用，不得利用陌趣会员功能获取不正当利益、如您存在不当行为，违反管理规定，或以任何形式损害陌趣或其他陌趣用户利益时，陌趣有权暂停或取消您的陌趣会员资格，且不退还已收取的会员费。此外，您还需承担因此引发的一切责任和后果。</p>

        <p> 3、如果您因违反陌趣《用户协议》等协议或平台规则而被限制账号权限(包括禁言、账号锁定、封禁等〕，则您的相关会员权益/功能将会受到一定限制。</p>
        <p>
          4、您确保明确了解并同意陌趣会员的付费方式，您知悉通过这些付费方式可能存在一定的商业风险包括但不限于不法分子利用您的账户或银行卡等进行违法活动)，这些商业风险给您造成的一切经济损失，以及向侵权方追究信权责任和追究责任不能等后果均由您个人自行承担。</p>
        <p> 5、陌趣不对您因为第三方的行为或者不作为造成的损失承担任何责任和赔偿，包括但不限于支付服务、网络接入服务等任何第三方的行为。</p>
        <p> 6、陌趣会员账号只允许在最多1个设备上使用，且同一时间内同一账号仅可在1个设备上登录使用，您需自行承担超范围使用而导致的任何后果。</p>
      </article>
      <article>
        <h2>五、陌趣的权利与义务</h2>
        <p>1、您承诺并同意陌趣享有对您在陌趣服务(包括免费和付费服务，下同)使月过程中一切活动的监督、提示和检查等权利，如您的行为违反有关法律法规或违反本协议中的相关条款，陌趣有权要求您改正并追究您的责任。</p>

        <p>2、您承诺并同意对于您在使用陌趣服务的过程中存在的一切违反法律法规及本机议的行为，陌趣有权取消您的会员资格并无需给了任何朴偿，同时由您个人承担全部责任。</p>
        <p>3、你承诺并同意对于您因个人的不良使用等原因造成的被陌趣官方封禁账号的情况，陌趣不予以任何退款和补偿，您需自行承担由此导致的一切后果。</p>

        <p>4、陌趣在目前技术水平下，最大程度地保障陌趣会员服务的正常运行，以维护您的利益，陌趣对陌趣会员服务不作任何明示或暗示的保证，包括但不限于对陌趣会员服务为可适用性、持续性等。</p>

        <p> 5、陌趣有权根据业务发展规划、国家法律法规或监管部的要求，调整、修改、完善、优化、升级或取消陌趣会员服务，您不得因此追究陌趣的违约或侵权责任。</p>
      </article>
      <article>
        <h2>六、会员服务的中断与终止</h2>
        <p>1、陌趣会员资格期满后，陌趣将终上向您提供陌趣会员专享服务功能，并取消您的会员资格，您可以通过继续支付会员服务继续享受会员资格及其功能服务。</p>
        <p>2、基于会员为虚拟服务，陌趣不支持会员用户在已开通的会员服务期间单方面要求终止服务和退还任何已缴纳的费用。</p>
        <p>
          3、对于因服务器的死机、网络或数据库故障、软件升级、服务器维修、调整及升级等问题，或其他无法预见或不可抗拒的事由，包括但不限于政府行为、自然灾害、黑客攻击等造成的服务中断等问题，陌趣会尽量以最快的速度通知陌趣会员，但是不承担由此给您带来的任何损失，同时不退还任何您已缴纳的服务费用。</p>
        <p>4、陌趣保留随时修改或中断服务而无通知您的权利，对于非因陌趣故意造成的所有服务的中断或终上而造成的任何损失，陌趣无需须承但任何责任及赔偿。</p>
        <p>5 若您违反或被陌趣视为违反本协议或《用户协议》的相关条款，或从事以下行为，包括但不限于</p>
        <p>(1)发布、传送、传播、储存违反国家法律、危害国家安全统一、社会稳定、公序良俗、社会公德以及侮辱、诽谤、淫秽、暴力的内容；</p>
        <p>(2)发布、传送、传播、储存侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的内容；</p>
        <p>(3)虚构事实、隐瞒真相以误导、救骗他人:</p>
        <p>(4)发表、传送、传播广告信息、诈骗信息、诱导消费信息及垃圾信息;</p>
        <p>(5)使用假账号或注册小号；</p>
        <p>(6)从事其他违反法律法规、政策及公序良俗、社会公德等的行为。
          则陌趣有权在发现该等情形后，在不通知您的情况下，随时停止向您提供用户服务和陌趣会员专享的所有功能服务，甚至永久封禁您的账户和使用权限。已充值陌趣会员的，不追还任何已缴纳的费用，也不承担任何其他损失。因该等行为造成陌趣损失，且已缴纳费用不足以弥补陌趣损失的，陌趣有权向您继续追偿。
        </p>
      </article>
      <article>
        <h2> 七、不可归责于陌趣的事由</h2>
        <p>1、陌趣将尽最大努力为您提供与陌趣会员眼务相关支持，但由于技术有限，陌趣无法保证相关服务一定能满足您的要求(包括但不限于网络服务的及时性、安全性、准确性)。</p>
        <p>2，因下述不可抗力及不可归责于陌趣的原因而产生的损失，陌趣不承担任何责任，若由此给您造成损失的，您同意放弃追究陌趣的责任。但陌趣将尽力减少因此而给您造成的损失和影响:</p>
        <p>(1)因台风、风暴、地震、海啸、洪水、停电、战争、恐怖袭击、疫情等不可抗力或社会实事件如战争、动乱、政府行为等之因素导致购买系统或服务不能正常运作:</p>
        <p>(2)鉴于互联网之特殊性质，不可抗力亦包括黑客攻击、电信部门技术调整、您的电脑软件、系统、硬件和通信线路出现故障等导致之影响、因政府管制导致的暂时关闭、病毒、木马或其他恶意程序侵袭等影响互联网正常运行之情形;</p>
        <p> (3） 由于政府命令，法律法规的变更，司法机关行政机关的命令，裁定等原因向导致的系统服务中断、终止或延迟;</p>
        <p>(4)由于陌趣链接或合作的其它网站(如网上银行、支付渠道等)所造成的银行账户信息、身份有息泄露及由此而导致的任何法律争议和后果:</p>
        <p> (5)如系统护或升级的要而需暂停陌趣会员服务时;</p>
        <p> (6)其他陌趣无法控制或合理预见的情形或不应归责于陌趣的原因导致的损失。</p>
      </article>
      <article>
        <h2>八、如何联系我们</h2>

        <p>1、 您可以通过“陌趣漂流瓶”客服与我们联系，我们将在15天内回复您的请求。</p>
        <p> 2、 如发现个人信息可能被泄露，您可以发邮件至moqunet@163.com投诉举报。如果您对我们的回复不满意，特别是您认为我们的个人信息处理行为损害了您的合法权益，
          您还可以通过向本协议签订地有管辖权的法院提起诉讼来寻求解决方案。</p>
      </article>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Vip',
  data() {
    return {
    }
  },
  mounted() {
    let dark = this.$route.query.dark; // John
    let html = document.documentElement
    if (dark=='true') {
      html.classList.add('dark')
    } else {
      html.classList.remove('dark')
    }
  }
}
</script>
