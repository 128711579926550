<template>
    <ul class="v7_classLst">
    <li class="item" @click="clickLi(1,'常见问题')"><span>常见问题</span></li>
    <li class="line"></li>
    <li class="item" @click="clickLi(2,'快速入门')"><span>快速入门</span></li>
    <li class="line"></li>
    <li class="item" @click="clickLi(3,'功能使用简介')"><span>功能使用简介</span></li>
    <li class="line"></li>
    <li class="item" @click="clickLi(4,'封禁账号问题')"><span>封禁账号问题</span></li>
    <li class="line"></li>
  </ul>
</template>
<script>
export default {
  name: 'help',
  data() {
    return {
      dark:false
    }
  },
  created() {
    // 初始化
    this.initList()
  },
  mounted() {
    let dark = this.$route.query.dark; // John
    let html = document.documentElement
    if (dark=='true') {
      html.classList.add('dark')
      this.dark=dark
    } else {
      html.classList.remove('dark')
    }
  },


  methods: {
    initList: function () {
      if (isNaN(this.type)) {
        this.list = []
        document.title = '帮助中心'
        console.log()
      }
    },
    clickLi: function (id) {
      this.$router.push({name: 'ContentHelp', query: {listId: id ,dark:this.dark }})
    }
  }
}
</script>
