import Vue from 'vue'
import '@/assets/css/font.css'
import '@/assets/css/common.css'
import '@/assets/css/my-dark-theme.css'
import App from './App.vue'
import router from './router'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
Vue.config.productionTip = false
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
new Vue({
  el: '#app',
  router,
  render: h => h(App)
}).$mount("#app")
