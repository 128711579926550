<template>
  <div class="main-body" >
      <img :src="imgUrl" class="app_banner_css">
  </div>



</template>

<script>
export default {
  name: 'CleanNet',
  data() {
    return {
      imgUrl: require('../../../assets/banner/clean_net.png')
    }
  },
}

</script>
<style>
body {
  font-size: 0.0rem !important;
}
</style>

