<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
  created() {
    // this.$nextTick(() => {
    //   // 禁止右键
    //   document.oncontextmenu = new Function("event.returnValue=false");
    //   // 禁止F12
    //   document.onkeydown = new Function("event.returnValue=false");
    // });
  },
}
</script>

<style>
body {
  margin: 0;
  padding: 0;
}
</style>
